<template>
  <div class="page-container">
    <div class="header-body">
      <el-card>
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <el-input
                placeholder="请输入姓名"
                class="input-with-select"
                clearable
                v-model.trim="queryInfo.name"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="searchHandle"
                ></el-button>
              </el-input>
            </div>
          </el-col>
        </el-row>
      </el-card>
    </div>
    <div class="table-body">
      <el-card>
        <el-table :data="orderList" style="width: 100%" border stripe>
          <el-table-column type="index" label="ID" width="50">
          </el-table-column>
          <el-table-column prop="orderNum" label="订单号"> </el-table-column>
          <el-table-column prop="name" label="姓名"> </el-table-column>
          <el-table-column prop="phone" label="手机号" width="150">
          </el-table-column>
          <el-table-column prop="openId" label="微信openid"> </el-table-column>
          <el-table-column prop="price" label="学费金额" width="150">
          </el-table-column>
          <el-table-column prop="gmtCreate" label="创建时间"> </el-table-column>
        </el-table>
      </el-card>
    </div>
    <div class="pagination-body">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.currentPage"
        :page-sizes="[10, 20, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { getReadyLearnPayList } from '@/api/api'
import axios from 'axios'
import { host } from '@/utils/service/url'
export default {
  data () {
    return {
      orderList: [],
      previewDialog: false,
      queryInfo: {
        currentPage: 1,
        pageSize: 10,
        orderNum: '',
        name: ''
      },
      total: 0
    }
  },
  created () {
    this.getReadyLearnPayList()
  },
  methods: {
    /* 获取订单列表 */
    getReadyLearnPayList () {
      getReadyLearnPayList(this.queryInfo).then(res => {
        this.orderList = res.data.list
        this.total = res.data.total
      })
    },
    /* 根据订单号搜索 */
    searchHandle () {
      this.queryInfo.currentPage = 1
      this.getReadyLearnPayList()
    },
    /* 分页选择 */
    handleSizeChange (size) {
      this.queryInfo.pageSize = size
      this.getReadyLearnPayList()
    },
    handleCurrentChange (size) {
      this.queryInfo.currentPage = size
      this.getReadyLearnPayList()
    },
    /* 导出excel */
    exportHandle () {
      axios({
        url: host + '/learnPay/exportExecl',
        method: 'post',
        responseType: 'blob',
        data: {},
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          Authorization: window.sessionStorage.getItem('token')
        }
      }).then(res => {
        const blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        })
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = `order-${Math.floor(Math.random() * 10000)}.xlsx`
        document.body.appendChild(a)
        a.click()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  .header-body {
    .handlebar {
      padding: 20px;
      text-align: right;
    }
  }
  .pagination-body {
    .el-pagination {
      text-align: center;
      padding: 20px 0;
    }
  }
}
</style>
